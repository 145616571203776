import React, {useState, useEffect} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FaSave} from "react-icons/fa";

const removeDup = (arr) => {
    return arr.filter((item, index, inputArray) => inputArray.indexOf(item) === index);
}

const getObjFromSource = (source, arr) => {
    let temp = arr.filter(item => item.source === source.split("=")[1]);
    return temp[0];
}

function validURL(str) {
    var pattern = new RegExp("^(?:(?:http(?:s)?|ftp)://)(?:\\S+(?::(?:\\S)*)?@)?(?:(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)(?:\\.(?:[a-z0-9\u00a1-\uffff](?:-)*)*(?:[a-z0-9\u00a1-\uffff])+)*(?:\\.(?:[a-z0-9\u00a1-\uffff]){2,})(?::(?:\\d){2,5})?(?:/(?:\\S)*)?$"); // fragment locator
    return !!pattern.test(str);
}

export const UTMBuilder = ({data}) => {
    const [utmlink, setUtmlink] = useState("");
    const [copied, setCopied] = useState(false);
    const [domain, setDomain] = useState("");
    const [path, setPath] = useState("");
    const [source, setSource] = useState("");
    const [medium, setMedium] = useState("");
    const [campaign, setCampaign] = useState("");
    const [term, setTerm] = useState("");
    const [content, setContent] = useState("");
    const [campaignId, setCampaignId] = useState("");
    const [err, setErr] = useState(0);

    useEffect(() => {
        if (validURL(path) && validatePath(path)) {
            let temp = ('https://' + new URL(path).host + new URL(path).pathname + '?' + campaignId + source + medium + campaign + term + content).toLowerCase();
            temp = temp.split('?')[0] + '?' + temp.split('?')[1].slice(1)
            setUtmlink(temp);
        } else {
            // setDomain(document.getElementById('domain').value);
            setUtmlink("");
        }
    }, [domain, campaignId, source, medium, campaign, term, content, path]);

    useEffect(() => {
        if (data && source) {
            const today = new Date();
            const myDate =
                ("0" + today.getDate()).slice(-2) +
                ("0" + (today.getMonth() + 1)).slice(-2) +
                today.getFullYear().toString().slice(-2);
            let value = data && source && getObjFromSource(source, data.utms).campaign;
            value ? setCampaign(`&utm_campaign=${value}_${myDate}`) : setCampaign("");
            document.getElementById('campaign').value = value;

            let temp_term = data && source && getObjFromSource(source, data.utms).term;
            document.querySelector('#termCheckbox:checked') !== null ? setTerm(`&utm_term=${temp_term}`) : setTerm("");
            document.getElementById('keyword').value = temp_term;
        }
    }, [data, source])

    const validatePath = (val) => {
        let temp = new URL(val)
        if (data.domains) {
            return data.domains && data.domains.split(',').filter(item => item.trim() === temp.host).length > 0
        } else {
            return 'airfleet.co' === temp.host;
        }
    }

    const handleChange = (e) => {
        const value = encodeURIComponent(e.target.value);
        const id = e.target.id;


        if (id === "path") {
            setPath(e.target.value);
            if (validURL(e.target.value)) {
                if (validatePath(e.target.value)) {
                    setErr(0)
                } else {
                    setErr(2)
                }
            } else {
                setErr(1)
            }
        }
        if (id === "campaignId") value && document.querySelector('#campaignIdCheckbox:checked') !== null ? setCampaignId(`&utm_id=${value}`) : setCampaignId(``);
        if (id === "source")
            value ? setSource(`&utm_source=${value}`) : setSource("");
        if (id === "medium")
            value ? setMedium(`&utm_medium=${value}`) : setMedium("");
        if (id === "campaign") {
            const today = new Date();
            const myDate =
                ("0" + today.getDate()).slice(-2) +
                ("0" + (today.getMonth() + 1)).slice(-2) +
                today.getFullYear().toString().slice(-2);
            value ? setCampaign(`&utm_campaign=${value}_${myDate}`) : setCampaign("");
        }
        if (id === "campaignIdCheckbox") {
            const checked = e.target.checked;
            const campaign_temp = document.querySelector('#campaignId').value;

            checked && campaign_temp ? setCampaignId(`&utm_id=${campaign_temp}`) : setCampaignId("");
        }
        if (id === "termCheckbox") {
            const checked = e.target.checked;
            const term_temp = document.querySelector('#term').value;

            checked && term_temp && term_temp.length > 3 ? setTerm(`&utm_term=${term_temp}`) : setTerm("");
        }
        if (id === "term") value && value.length > 3 && document.querySelector('#termCheckbox:checked') !== null ? setTerm(`&utm_term=${value}`) : setTerm("");
        if (id === "contentCheckbox") {
            const checked = e.target.checked;
            const content_temp = document.querySelector('#content').value;

            checked && content_temp ? setContent(`&utm_content=${content_temp}`) : setContent("");
        }
        if (id === "content")
            value ? setContent(`&utm_content=${value}`) : setContent("");
        if (id === "keywordCheckbox") {
            const checked = e.target.checked;

            if (checked) {
                document.querySelector('#keyword').style.display = 'block';
            } else {
                document.querySelector('#keyword').style.display = 'none';
            }

            setTerm(keywordAudience());
        }
        if (id === "audienceCheckbox") {
            const checked = e.target.checked;

            if (checked) {
                document.querySelector('#audience').style.display = 'block';
            } else {
                document.querySelector('#audience').style.display = 'none';
            }

            setTerm(keywordAudience());
        }
        if (id === "audience") setTerm(keywordAudience());
        if (id === "keyword") setTerm(keywordAudience());

        setCopied(false);
    };

    const checkboxChange = (e) => {
        const id = '#' + e.target.getAttribute('data-inputId');

        if (!e.target.checked) {
            document.querySelector(id).style.display = 'none';
        } else {
            document.querySelector(id).style.display = 'block';
        }

        handleChange(e);
    }

    const keywordAudience = () => {
        const keyword = encodeURIComponent(document.querySelector('#keyword').value);
        const keywordCheckbox = document.querySelector('#keywordCheckbox:checked');
        const audience = encodeURIComponent(document.querySelector('#audience').value);
        const audienceCheckbox = document.querySelector('#audienceCheckbox:checked');

        let value = '&utm_term=';

        if (keywordCheckbox !== null && audienceCheckbox !== null) {
            value += `kw_${keyword}_a_${audience}`;
        } else if (keywordCheckbox !== null) {
            value += `kw_${keyword}`;
        } else if (audienceCheckbox !== null) {
            value += `a_${audience}`;
        } else {
            return '';
        }

        return value;
    }


    return (
        <section className="container">
            <div className="row justify-content-md-center">
                <div className="col-10">
                    <form>
                        <div className="row mb-3 align-items-center">
                            <div className="col-3">
                                <label htmlFor="path" className="col-form-label">
                                    URL <span className="mandatory-star">*</span>
                                </label>
                            </div>
                            <div className="col-5">
                                <input
                                    type="text"
                                    id="path"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    onChange={handleChange}
                                    value={path}
                                    placeholder="You can copy/paste your URL here."
                                />
                            </div>
                            <div className="col-4">
                <span id="domainHelp" className="form-text" style={{color: 'red'}}>
                  {err === 1 && 'Please input valid url.'}
                    {err === 2 && `Please select ${data.domains ? `one of ${data.domains}` : 'airfleet.co'}`}
                </span>
                            </div>
                        </div>

                        {/* <div className="row mb-3 align-items-center">
              <div className="col-3">
                <label htmlFor="domain" className="col-form-label">
                  Page/Path
                </label>
              </div>
              <div className="col-5">
                <input
                  type="text"
                  id="path"
                  className="form-control"
                  aria-describedby="passwordHelpInline"
                  onChange={handleChange}
                  value={path}
                  placeholder="You can copy/paste your URL here."
                />
              </div>
            </div> */}

                        <div className="row mb-3 align-items-center">
                            <div className="col-3">
                                <label htmlFor="campaignId" className="col-form-label">
                                    Campaign ID
                                </label>
                            </div>
                            <div className="col-5">
                                <input
                                    type="checkbox"
                                    id="campaignIdCheckbox"
                                    aria-describedby="passwordHelpInline"
                                    data-inputId="campaignId"
                                    onChange={checkboxChange}
                                    disabled={err}
                                />
                                <span style={{'padding-left': '5px'}}>Does your campaign include a tracking id?</span>
                                <input
                                    type="text"
                                    id="campaignId"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    onChange={handleChange}
                                    style={{display: 'none'}}
                                    disabled={err}
                                />
                            </div>
                            <div className="col-4">
                <span id="passwordHelpInline" className="form-text">
                  Add the unique campaign ID code from the channel used.
[This is not the Marketo code, the code comes from the advertizing platform]
                </span>
                            </div>
                        </div>

                        <div className="row mb-3 align-items-center">
                            <div className="col-3">
                                <label htmlFor="source" className="col-form-label">
                                    UTM Source <span className="mandatory-star">*</span>
                                </label>
                            </div>
                            <div className="col-5 select-container">
                                <select
                                    type="text"
                                    id="source"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    onChange={handleChange}
                                    disabled={err}
                                >
                                    <option value="">Select</option>
                                    {
                                        data && removeDup(data.utms.map(item => item.source)).map((item, index) =>
                                            <option value={item} key={index}>{item}</option>)
                                    }
                                </select>
                            </div>
                            <div className="col-4">
                <span id="passwordHelpInline" className="form-text">
                  Choose our original and true source for channel/funnel for your campaign
                </span>
                            </div>
                        </div>

                        <div className="row mb-3 align-items-center">
                            <div className="col-3">
                                <label htmlFor="medium" className="col-form-label">
                                    UTM Medium <span className="mandatory-star">*</span>
                                </label>
                            </div>
                            <div className="col-5 select-container">
                                <select
                                    type="text"
                                    id="medium"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    onChange={handleChange}
                                    disabled={err}
                                >
                                    <option value="">Select</option>
                                    {data && source && getObjFromSource(source, data.utms).medium.split(",").map((item, index) => (
                                        <option value={item.trim()} key={index}>{item.trim()}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-4">
                <span id="passwordHelpInline" className="form-text">
                  Choose your effort - How are we measuring campaign success?
                </span>
                            </div>
                        </div>

                        <div className="row mb-3 align-items-center">
                            <div className="col-3">
                                <label htmlFor="campaign" className="col-form-label">
                                    Campaign Name <span className="mandatory-star">*</span>
                                </label>
                            </div>
                            <div className="col-5">
                                <input
                                    type="text"
                                    id="campaign"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    onChange={handleChange}
                                    disabled={err}
                                />
                            </div>
                            <div className="col-4">
                <span id="passwordHelpInline" className="form-text">
                  Give your campaign a unique name (by default it will be today’s date) 
                </span>
                            </div>
                        </div>

                        <div className="row mb-3 align-items-center">
                            <div className="col-3">
                                <label htmlFor="term" className="col-form-label">
                                    Keyword / Audience / Keyword & Audience
                                </label>
                            </div>
                            <div className="col-5">
                                <input
                                    type="checkbox"
                                    id="keywordCheckbox"
                                    aria-describedby="passwordHelpInline"
                                    data-inputId="keyword"
                                    onChange={checkboxChange}
                                    disabled={err}
                                />
                                <span style={{'padding-left': '5px'}}>Keyword</span>
                                <input
                                    type="text"
                                    id="keyword"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    onChange={handleChange}
                                    style={{display: 'none'}}
                                    disabled={err}
                                /><br/>
                                <input
                                    type="checkbox"
                                    id="audienceCheckbox"
                                    aria-describedby="passwordHelpInline"
                                    data-inputId="audience"
                                    onChange={checkboxChange}
                                    disabled={err}
                                />
                                <span style={{'padding-left': '5px'}}>Audience</span>
                                <input
                                    type="text"
                                    id="audience"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    onChange={handleChange}
                                    style={{display: 'none'}}
                                    disabled={err}
                                />
                            </div>
                            <div className="col-4">
                <span id="passwordHelpInline" className="form-text">
                  Choose either Keyword or Audience or both Keyword & Audience
                </span>
                            </div>
                        </div>

                        <div className="row mb-3 align-items-center">
                            <div className="col-3">
                                <label htmlFor="content" className="col-form-label">
                                    Agency name
                                </label>
                            </div>
                            <div className="col-5">
                                <input
                                    type="checkbox"
                                    id="contentCheckbox"
                                    aria-describedby="passwordHelpInline"
                                    data-inputId="content"
                                    onChange={checkboxChange}
                                    disabled={err}
                                />
                                <span style={{'padding-left': '5px'}}>I'm an Agency</span>
                                <input
                                    type="text"
                                    id="content"
                                    className="form-control"
                                    aria-describedby="passwordHelpInline"
                                    onChange={handleChange}
                                    style={{display: 'none'}}
                                    disabled={err}
                                />
                            </div>
                        </div>
                    </form>
                    <div className="row mb-3 align-items-center mt-4 text-center">
                        <h3 className="mb-4">Generated UTM campaign URL</h3>
                        <p className='small'>Share the below url in social media, ad campaign, and view the traffic in
                            Google Analytics.</p>

                        <textarea className="form-control" value={utmlink} readonly="true"/>

                        {!copied ? (
                            <CopyToClipboard text={utmlink} onCopy={() => setCopied(true)}>
                                <button type="button" className="btn btn-secondary mt-3">
                                    <FaSave/> Copy URL
                                </button>
                            </CopyToClipboard>
                        ) : (
                            <button type="button" className="btn btn-secondary mt-3">
                                Copied!
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <hr/>
        </section>
    );
};
