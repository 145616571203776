import React from "react";

export const Footer = () => {
  return (
    <footer className="mb-5 pt-1 text-muted text-center text-small">
      <p className="mb-1">
        © 2021 Developed by{" "}
        <a href="https://www.airfleet.co/" target='_blank' rel="noreferrer">Airfleet.co</a>
      </p>
      <p className="mb-1">
        Need Support? Contact us{" "}
        <a href="mailto:utms@airfleet.co" target='_blank' rel="noreferrer">utms@airfleet.co</a>
      </p>
    </footer>
  );
};
