import React, { Fragment } from "react";

export const Navbar = ({data}) => {

  return (
    <Fragment>
      <nav className="navbar navbar-dark">
        <div className="container">
          <div>
            {data && data.logo && <img src={data.logo} alt="client logo"/>}
          </div>
          <a href="https://www.airfleet.co/">
            <img src={'https://www.airfleet.co/_next/image?url=https%3A%2F%2Fairfleetwp.s3.amazonaws.com%2F2021%2F03%2F30115300%2FLogoBlue1.svg&w=128&q=75'} alt="logo"/>
          </a>
        </div>
      </nav>
    </Fragment>
  );
};
