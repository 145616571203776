import * as React from "react";
import { Helmet } from "react-helmet";

import { Navbar } from "../components/Navbar";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { UTMBuilder } from "../components/UTMBuilder";

const IndexPage = () => {

  const isBrowser = () => typeof window !== "undefined";
  const clientId = isBrowser() && window.location && (new URL(window.location.href).searchParams.get('client') || 'www.airfleet.co');
  const [data, setData] = React.useState();
  React.useEffect(() => {
    let url = 'https://script.google.com/macros/s/AKfycbzRxLNPMLCh9Wrok-Rl4eoL8ZtcZsNSUNE2Th19NKd9VI2I0L-GRVoemQfMLyw_qB_7Rg/exec'
    if (clientId) {
      url = url + `?clientId=${clientId}`
    }
    fetch(url, { method: 'get', redirect: 'follow'})
    .then(res =>res.json())
    .then(result => {
      console.log('data ===>', result)
      setData(result);
    }).catch(err => console.error(err))
  }, [clientId]);

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>UTM Builder for Google Analytics</title>
        <meta content="Free URL builder to create UTM codes for all your Google Analytics campaign URLs" name="description"></meta>
        <link
          href="https://fonts.googleapis.com/css?family=Lato"
          rel="stylesheet"
        />
      </Helmet>
      {
        data ?
        <>
          <Navbar data={data}/>
          <Header />
          <UTMBuilder data={data}/>
          <Footer />
        </> :
        <div className="loading">
          <div class="lds-dual-ring"></div>
        </div>
      }
    </React.Fragment>
  );
};

export default IndexPage;
